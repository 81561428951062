<template>
  <div>
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <b-row v-if="isContentLoaded">
      <b-col md="12">
        <apex-line-chart :data-object="reportData.appointmentCountByYears"></apex-line-chart>
      </b-col>
      <b-col md="12" v-if="reportData.appointmentCountByStaff.xaxis.categories.length > 0">
        <apex-column-chart :data-object="reportData.appointmentCountByStaff"></apex-column-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByRooms.xaxis.categories.length > 0">
         <apex-bar-chart :data-object="reportData.appointmentCountByRooms"></apex-bar-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByStatus.series.length > 0">
        <apex-pie-chart :data-object="reportData.appointmentCountByStatus"></apex-pie-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByDays.series.length > 0">
        <apex-pie-chart :data-object="reportData.appointmentCountByDays"></apex-pie-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByHours.series.length > 0">
        <apex-pie-chart :data-object="reportData.appointmentCountByHours"></apex-pie-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByType.series.length > 0">
        <apex-pie-chart :data-object="reportData.appointmentCountByType"></apex-pie-chart>
      </b-col>
      <b-col md="6" v-if="reportData.appointmentCountByFirstTime.series.length > 0">
        <apex-pie-chart :data-object="reportData.appointmentCountByFirstTime"></apex-pie-chart>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import reportService from '../../../services/report';

  import Loader from '../../../components/shared/Loader';
  import apexPieChart from '../../../components/apexCharts/ApexPieChart';
  import apexBarChart from '../../../components/apexCharts/ApexBarChart';
  import apexLineChart from '../../../components/apexCharts/ApexLineChart';
  import apexColumnChart from '../../../components/apexCharts/ApexColumnChart';

  export default {
    name: 'AppointmentTrend',
    props: {
      getPayload: String
    },
    components: {
      Loader,
      apexPieChart,
      apexBarChart,
      apexLineChart,
      apexColumnChart
    },
    data() {
      return {
        reportData: {},
        pageName: 'appointment',
        isContentLoaded: false,
      }
    },
    methods: {
      getData(url) {
        this.reportData = {};
        reportService.getReportData(url, this.pageName)
          .then(response => {
            if (response) {
              this.reportData = response;
            }
            else {
              this.$toastr.error(this.$t("error"));
            }
          }).finally(() => this.isContentLoaded = true)
      },
    },
    computed: {
      url: function () {
        return this.getPayload;
      },
    },
    watch: {
      url: {
        handler: function (val) {
          this.isContentLoaded = false
          this.getData(val);
        },
        immediate: true
      }
    },

    mounted: function () {
    }
  }
</script>
